<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">
              404
            </h1>
            <h4 class="pt-3">
              Ой! Ты заблудился.
            </h4>
            <p class="text-muted">
              Страница, которую вы ищете, не найдена. Вернитесь на <a
                href="/"
                class="btn px-0 btn-link"
              >главную страницу</a>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
};
</script>


<style scoped>

</style>
